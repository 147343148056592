'use strict'

###*
 # @ngdoc service
 # @name lpaDispatching.factory:Incidents

 # @description

###
angular
  .module 'lpaDispatching'
  .factory 'Incidents', [
    'Restangular'
    (Restangular) ->
      Restangular.service('lpa/incidents')
  ]
